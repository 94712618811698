<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>{{ l("协议内容导入") }}</span>
      </div>
    </div>
    <a-row :gutter="16" class="btn--container">
      <a-col class="gutter-row" :span="12" style="color: red; font-size: 20px">
        <!-- <a-button type="primary" @click="testUpload()">
          <a-icon type="download" />
          {{ l("上传文件") }}
        </a-button> -->
        温馨提示：先下载模板再导入协议内容模版
      </a-col>
      <a-col class="gutter-row" :span="12" style="text-align: right">
        <a-button @click="downLoad()">
          <a-icon type="download" />
          {{ l("下载模板") }}
        </a-button>
      </a-col>
    </a-row>
    <a-row :gutter="16" class="upload--container">
      <a-col class="gutter-row" :span="24">
        <a-upload-dragger
          ref="uploadExcel"
          name="file"
          :multiple="false"
          :action="importFromExcelUrl"
          accept=".xls,.xlsx"
          :headers="uploadHeaders"
          :before-upload="beforeUpload"
          @change="handleChange"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">将文件拖到此处或点击上传</p>
          <p class="ant-upload-hint">只支持.xls,.xlsx文件格式上传。</p>
        </a-upload-dragger>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { setInterval, setTimeout } from "timers";
import { AppConsts } from "@/abpPro/AppConsts";
import { fileDownloadService } from "@/shared/utils";
import { CommonServiceProxy } from "@/shared/common-service";
import fileHelper from "@/shared/helpers/fileHelper";

export default {
  mixins: [ModalComponentBase],
  name: "import-form",
  data() {
    return {
      spinning: false,
      fileList: [],
      _fileDownloadService: "",
      commonService: "",
      importFromExcelUrl:
        AppConsts.remoteServiceBaseUrl +
        "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/ImportAgreementContent",
      uploadHeaders: {},
    };
  },
  components: {},
  beforeCreate() {},
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.importFromExcelUrl = `${this.importFromExcelUrl}?formId=${this.formId}`;
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this._fileDownloadService = fileDownloadService;
    Object.assign(this.uploadHeaders, {
      Authorization: "Bearer " + abp.auth.getToken(),
    });
  },
  mounted() {},
  methods: {
    handleChange(info) {
      console.log("info::", info);
      const status = info.file.status;
      if (status !== "uploading") {
        console.log('status !== "uploading"', info.file, info.fileList);
      }
      if (status === "done") {
        let message = info.file.response.result.message;
        // this.$message.info(message);
        this.$message.success(`${info.file.name}导入成功(${message}).`);
        this.fileList = info.file.response && info.file.response.data;
        if (info.file.response.result.file)
          fileDownloadService.downloadTempFile(info.file.response.result.file);
        // console.log(this.dataList);
      } else if (status === "error") {
        let message = info.file.response.error.message;
        this.$message.error(`${info.file.name}导入失败(${message}).`);
      }
    },
    //限制文件上传大小
    beforeUpload(file) {
      console.log(file);
      var fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isFile = !(fileName != "xlsx" && fileName != "xls");

      if (!isFile) {
        this.$message.error("文件格式必须是xls或xlsx!");
      }
      const isLt10M = file.size / 1024 / 1024 <= 10;
      if (!isLt10M) {
        this.$message.error("上传的文件必须小于 10MB！");
      }
      return isFile && isLt10M;
    },
    //点击下载模板
    async downLoad() {
      this.spinning = true;
      if (this.type === "wywk") {
        try {
          fileHelper.downloadTemplateFile("网鱼网咖人员扩展信息导入模板.xlsx");
          this.spinning = false;
        } catch (error) {
          this.spinning = false;
        }
      } else {
        try {
          const fileDetail = await this.commonService.get({
            url:
              "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/GetExportTemplate",
            params: { formId: this.formId },
          });
          this._fileDownloadService.downloadTempFile(fileDetail);
          this.spinning = false;
        } catch (error) {
          this.spinning = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin: 0 20px !important;
}
.upload--container {
  margin: 20px !important;
}
</style>
