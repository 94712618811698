
import {regionData} from './city.js'
import { AppConsts } from "@/abpPro/AppConsts"
import { environment } from "@/environments/environment"

const gender = [
  {label: '保密', value: '0'},
  {label: '男', value: 'Male'},
  {label: '女', value: 'Female'}
]

const isMarried = [
  {label: '已婚', value: 1},
  {label: '未婚', value: 0}
]

const hadChild = [
  {label: '是', value: 1},
  {label: '否', value: 0}
]

const registeredResidenceCity = initRegionData('registeredResidenceCity')

const throughTheBook = initRegionData('throughTheBook')

function initRegionData(keyName) {
  let arr = []
  regionData.map((item, index) => {
    let opts = {}
    opts.value = item.n
    opts.label = item.n
    // opts.extra = keyName
    if (item.a && item.a.length) {
      let child = item.a
      let arr = []
      child.map((it ,i) => {
        arr.push({
          value: it.n,
          label: it.n,
          // extra: keyName
        })
      })
      opts.children = arr
    } else {
      opts.children = []
    }
    arr.push(opts)
  })
  return arr
}

const nation = initNation('nation')

function initNation(keyName) {
  let str = '汉族、满族、蒙古族、回族、藏族、维吾尔族、苗族、彝族、壮族、布依族、侗族、瑶族、白族、土家族、哈尼族、哈萨克族、傣族、黎族、傈僳族、佤族、畲族、高山族、拉祜族、水族、东乡族、纳西族、景颇族、柯尔克孜族、土族、达斡尔族、仫佬族、羌族、布朗族、撒拉族、毛南族、仡佬族、锡伯族、阿昌族、普米族、朝鲜族、塔吉克族、怒族、乌孜别克族、俄罗斯族、鄂温克族、德昂族、保安族、裕固族、京族、塔塔尔族、独龙族、鄂伦春族、赫哲族、门巴族、珞巴族、基诺族'
  let arr = str.split('、')
  return arr.map(item => {
    return {
      label: item,
      value: item,
      extra: keyName,
    }
  })

}

const natureOfRegisteredPermanentResidence = [
  {label: '本地城镇', value: '本地城镇', extra: 'natureOfRegisteredPermanentResidence'},
  {label: '本地农村', value: '本地农村', extra: 'natureOfRegisteredPermanentResidence'},
  {label: '外地城镇', value: '外地城镇', extra: 'natureOfRegisteredPermanentResidence'},
  {label: '外地农村', value: '外地农村', extra: 'natureOfRegisteredPermanentResidence'},
]

const clothingSize = [
  {label: 'XS', value: 'XS', extra: 'clothingSize'},
  {label: 'S', value: 'S', extra: 'clothingSize'},
  {label: 'M', value: 'M', extra: 'clothingSize'},
  {label: 'L', value: 'L', extra: 'clothingSize'},
  {label: 'XL', value: 'XL', extra: 'clothingSize'},
  {label: 'XXL', value: 'XXL', extra: 'clothingSize'},
  {label: 'XXXL', value: 'XXXL', extra: 'clothingSize'},
]

const bloodType = [
  {label: 'A型', value: 'A型', extra: 'bloodType'},
  {label: 'B型', value: 'B型', extra: 'bloodType'},
  {label: 'O型', value: 'O型', extra: 'bloodType'},
  {label: 'AB型', value: 'AB型', extra: 'bloodType'},
]

const country = [
  {label: '中国大陆', value: '中国大陆', extra: 'country'},
  {label: '中国香港', value: '中国香港', extra: 'country'},
  {label: '中国澳门', value: '中国澳门', extra: 'country'},
  {label: '中国台湾', value: '中国台湾', extra: 'country'},
]

export async function returnPersonnelExtendBasic(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'realName':
      return {name: '姓名', type: 'commonInput', disabled: true}
    case 'nation':
      return {name: '民族', type: 'commonInput', disabled: false, pickerShow: false,  dataList: nation}
    case 'politicalOutlook':
      return {name: '政治面貌', type: 'commonInput', disabled: false}
    case 'idType':
      return {name: '证件类型', type: 'commonInput', disabled: true}
    case 'idCard':
      return {name: '证件号码', type: 'commonInput', disabled: true}
    case 'gender':
      return {name: '性别', type: 'radio', disabled: false, dataList: gender}
    case 'birthday':
      return {name: '生日', type: 'datePicker', disabled: false, pickerShow: false}
    case 'isMarried':
      return {name: '是否已婚', type: 'radio', disabled: false,  dataList: isMarried}
    case 'hadChild':
      return {name: '是否已育', type: 'radio', disabled: false,  dataList: hadChild}
    case 'timeToWork':
      return {name: '参加工作时间', type: 'datePicker', disabled: false, pickerShow: false}
    case 'throughTheBook':
      return {name: '籍贯', type: 'cascader', disabled: false, pickerShow: false,  dataList: throughTheBook}
    case 'natureOfRegisteredPermanentResidence':
      return {name: '户口性质', type: 'commonSelect', disabled: false, pickerShow: false, dataList: natureOfRegisteredPermanentResidence}
    case 'registeredResidenceCity':
      return {name: '户籍城市', type: 'cascader', disabled: false, pickerShow: false,  dataList: registeredResidenceCity}
    case 'permanentAddress':
      return {name: '户籍地址', type: 'commonInput', disabled: false}
    case 'alias':
      return {name: '别名', type: 'commonInput', disabled: false}
    case 'nameUsedBefore':
      return {name: '曾用名', type: 'commonInput', disabled: false}
    case 'englishName':
      return {name: '英文名', type: 'commonInput', disabled: false}
    case 'idCardValidity':
      return {name: '身份证有效期', type: 'datePicker', disabled: false, pickerShow: false}
    case 'country':
      return {name: '国家地区', type: 'commonSelect', disabled: false, pickerShow: false, dataList: country}
    case 'bloodType':
      return {name: '血型', type: 'commonSelect', disabled: false, pickerShow: false, dataList: bloodType}
    case 'clothingSize':
      return {name: '服装尺寸', type: 'commonSelect', disabled: false, pickerShow: false, dataList: clothingSize}
    case 'healthStatus':
      return {name: '健康状况', type: 'commonInput', disabled: false}
    case 'validityOfHealthCertificate':
      return {name: '健康证有效期', type: 'datePicker', disabled: false, pickerShow: false}
    default:
      // console.log('没有匹配项')
      console.log(`key:${key} 没有匹配项`)
  }
}

export async function returnPersonnelExtendWageCard(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'account':
      return {name: '工资账户', type: 'commonInput', disabled: false}
    case 'bankName':
      return {name: '工资账户开户行', type: 'commonInput', disabled: false}
    case 'bankArea':
      return {name: '开户城市', type: 'commonInput', disabled: false}
    default:
      // throw new Error('没有匹配项')
      console.log(`key:${key} 没有匹配项`)
  }
}

const isFirstSocialSecurity = [
  {label: '是', value: 1},
  {label: '否', value: 0}
]

const isFirstAccumulationFund = [
  {label: '是', value: 1},
  {label: '否', value: 0}
]

export function returnPersonnelExtendSocialSecurityFund(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'isFirstSocialSecurity':
      return {name: '是否本地收集缴纳社保', type: 'radio', disabled: false, dataList: isFirstSocialSecurity}
    case 'socialSecurityAccount':
      return {name: '个人社保账号', type: 'commonInput', disabled: false}
    case 'isFirstAccumulationFund':
      return {name: '是否本地首次缴纳公积金', type: 'radio', disabled: false, dataList: isFirstAccumulationFund}
    case 'accumulationFundAccount':
      return {name: '个人公积金账号', type: 'commonInput', disabled: false}
    default:
      // throw new Error('没有匹配项')
      console.log(`key:${key} 没有匹配项`)
  }
}

export async function returnPersonnelExtendContact(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'personalEmail':
      return {name: '个人邮箱', type: 'commonInput', disabled: false}
    case 'qq':
      return {name: 'QQ号', type: 'commonInput', disabled: false}
    case 'wechat':
      return {name: '微信号', type: 'commonInput', disabled: false}
    case 'residentialAddress':
      return {name: '现居住地', type: 'commonInput', disabled: false}
    case 'workPhone':
      return {name: '工作电话', type: 'commonInput', disabled: false}
    case 'extensionNumber':
      return {name: '分机号', type: 'commonInput', disabled: false}
    case 'workEmail':
      return {name: '工作邮箱', type: 'commonInput', disabled: false}
    case 'phoneNumber':
      return {name: '手机号码', type: 'commonInput', disabled: true}
    default:
      console.log(`key:${key} 没有匹配项`)
  }
}

export async function returnPersonnelExtendEducationalExperiences(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'id':
      break
    case 'startTime':
      return {name: '教育开始时间', type: 'datePicker', disabled: false, pickerShow: false}
    case 'endTime':
      return {name: '教育结束时间', type: 'datePicker', disabled: false, pickerShow: false}
    case 'school':
      return {name: '毕业院校', type: 'commonInput', disabled: false}
    case 'major':
      return {name: '毕业专业', type: 'commonInput', disabled: false}
    case 'education':
      return {name: '学历', type: 'commonInput', disabled: false}
    case 'diplomaNo':
      return {name: '毕业证书编号', type: 'commonInput', disabled: false}
    case 'certificateNo':
      return {name: '学位证书编号', type: 'commonInput', disabled: false}
    case 'remarks':
      return {name: '教育备注', type: 'commonInput', disabled: false}
    case 'learningForm':
      return {name: '学习形式', type: 'commonInput', disabled: false}
    case 'degreeType':
      return {name: '学位类型', type: 'commonInput', disabled: false}
    case 'nameOfDegree':
      return {name: '学位名称', type: 'commonInput', disabled: false}
    default:
      console.log(`key:${key} 没有匹配项`)
  }
  
}

export async function returnPersonnelExtendWorkExperiences(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'id':
      break
    case 'startTime':
      return {name: '教育开始时间', type: 'datePicker', disabled: false, pickerShow: false}
    case 'endTime':
      return {name: '教育结束时间', type: 'datePicker', disabled: false, pickerShow: false}
    case 'workUnit':
      return {name: '工作单位', type: 'commonInput', disabled: false}
    case 'post':
      return {name: '职务', type: 'commonInput', disabled: false}
    case 'reasonsForLeaving':
      return {name: '离职原因', type: 'commonInput', disabled: false}
    case 'witness':
      return {name: '证明人', type: 'commonInput', disabled: false}
    case 'witnessContactNumber':
      return {name: '证明人联系电话', type: 'commonInput', disabled: false}
    case 'remarks':
      return {name: '工作备注', type: 'commonInput', disabled: false}
    case 'resignationInformation':
      return {name: '离职信息', type: 'commonInput', disabled: false}
    default:
      console.log(`key:${key} 没有匹配项`)
  }
}

const isMain = [
  {label: '是', value: 1},
  {label: '否', value: 0}
]

export async function returnPersonnelExtendEmergencyContact(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'name':
      return {name: '紧急联系人姓名', type: 'commonInput', disabled: false}
    case 'relation':
      return {name: '紧急联系人关系', type: 'commonInput', disabled: false}
    case 'phoneNumber':
      return {name: '紧急联系人电话', type: 'commonInput', disabled: false}
    case 'address':
      return {name: '紧急联系人地址', type: 'commonInput', disabled: false}
    case 'isMain':
      return {name: '是否为主要联系人', type: 'radio', disabled: false, disabled: false, dataList: isMain}
    default:
      console.log(`key:${key} 没有匹配项`)
  }
}


export async function returnPersonnelExtendMemberOfFamilies(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'id':
      break
    case 'name':
      return {name: '家庭成员姓名', type: 'commonInput', disabled: false}
    case 'contactNumber':
      return {name: '家庭联系人电话', type: 'commonInput', disabled: false}
    case 'birthday':
      return {name: '出生年月', type: 'datePicker', disabled: false, pickerShow: false}
    case 'phoneNumber':
      return {name: '成员电话', type: 'commonInput', disabled: false}
    case 'workUnit':
      return {name: '工作单位', type: 'commonInput', disabled: false}
    case 'position':
      return {name: '任职职位', type: 'commonInput', disabled: false}
    case 'degreeOfEducation':
      return {name: '成员文化程度', type: 'commonInput', disabled: false}
    case 'relationship':
      return {name: '与本人关系', type: 'commonInput', disabled: false}
    default:
      console.log(`key:${key} 没有匹配项`)
  }
}

export async function returnPersonnelExtendCertificates(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'id':
      break
    case 'category':
      return {name: '证书/证件类型', type: 'commonInput', disabled: false}
    case 'name':
      return {name: '证书名称', type: 'commonInput', disabled: false}
    case 'issuingAuthority':
      return {name: '发证机构', type: 'commonInput', disabled: false}
    case 'dateOfIssue':
      return {name: '发证日期', type: 'datePicker', disabled: false, pickerShow: false}
    case 'effectiveStartDate':
      return {name: '有效期起始日', type: 'datePicker', disabled: false, pickerShow: false}
    case 'effectiveEndDate':
      return {name: '有效期到期日', type: 'datePicker', disabled: false, pickerShow: false}
    case 'remarks':
      return {name: '证件备注', type: 'commonInput', disabled: false}
    case 'number':
      return {name: '证书/证件编号', type: 'commonInput', disabled: false}
    case 'level':
      return {name: '级别', type: 'commonInput', disabled: false}
    case 'major':
      return {name: '专业', type: 'commonInput', disabled: false}
    case 'reviewDueDate':
      return {name: '复审到期日', type: 'datePicker', disabled: false, pickerShow: false}
    default:
      console.log(`key:${key} 没有匹配项`)
  }
}


export async function returnPersonnelExtendTrainingExperiences(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'id':
      break
    case 'name':
      return {name: '培训名称', type: 'commonInput', disabled: false}
    case 'time':
      return {name: '培训时间', type: 'datePicker', disabled: false, pickerShow: false}
    case 'certificateName':
      return {name: '培训证书名称', type: 'commonInput', disabled: false}
    case 'company':
      return {name: '培训单位', type: 'commonInput', disabled: false}
    case 'totalClassHours':
      return {name: '培训总学时', type: 'commonInput', disabled: false}
    case 'number':
      return {name: '培训证书编号', type: 'commonInput', disabled: false}
    case 'score':
      return {name: '培训成绩', type: 'commonInput', disabled: false}
    case 'startTime':
      return {name: '培训开始时间', type: 'datePicker', disabled: false, pickerShow: false}
    case 'endTime':
      return {name: '培训结束时间', type: 'datePicker', disabled: false, pickerShow: false}
    default:
      console.log(`key:${key} 没有匹配项`)
  }
}


export async function returnPersonnelExtendRelevantAttachment(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'idCardPhoto':
      return {name: '身份证照片', type: 'upload', disabled: false, maxCount: 3}
    case 'bankCardPhoto':
      return {name: '银行卡照片', type: 'upload', disabled: false, maxCount: 3}
    case 'studentIdCardPhoto':
      return {name: '学生证照片', type: 'upload', disabled: false, maxCount: 3}
    case 'relevantDiploma':
      return {name: '相关毕业证书', type: 'upload', disabled: false, maxCount: 3}
    case 'healthCertificatePhoto':
      return {name: '健康证照片', type: 'upload', disabled: false, maxCount: 3}
    default:
      console.log(`key:${key} 没有匹配项`)
  }
}


export function returnInputDetailTitle(key) {
  switch(key) {
    case 'personnelExtendEducationalExperiences':
      return '教育经历'
    case 'personnelExtendWorkExperiences':
      return '工作经历'
    case 'personnelExtendMemberOfFamilies':
      return '家庭成员'
    case 'personnelExtendCertificates':
      return '证书/证件'
    case 'personnelExtendTrainingExperiences':
      return '培训经历'
    default:
      console.log(`key:${key} 没有匹配项`)
  }
}

const personnelExtendSign = [
  {label: '是', value: 1},
  {label: '否', value: 0}
]

export function returnPersonnelExtendSign(key) {
  switch(key) {
    case 'personnelId':
			break
    case 'sign':
      return {name: '是否已签名', type: 'commonSelect', disabled: false, pickerShow: false, dataList: personnelExtendSign}
    default:
      console.log(`key:${key} 没有匹配项`)
  }
}


export function sObjReturn(key) {
  switch(key) {
    case 'personnelExtendBasic':
      return {
        title: '基本信息',
        fn: returnPersonnelExtendBasic,
      }
    case 'personnelExtendWageCard':
      return {
        title: '工资卡信息',
        fn: returnPersonnelExtendWageCard
      }
    case 'personnelExtendSocialSecurityFund':
      return {
        title: '社保公积金信息',
        fn: returnPersonnelExtendSocialSecurityFund
      }
    case 'personnelExtendContact':
      return {
        title: '联系信息',
        fn: returnPersonnelExtendContact
      }
    case 'personnelExtendEmergencyContact':
      return {
        title: '紧急联系人信息',
        fn: returnPersonnelExtendEmergencyContact
      }
    case 'personnelExtendSign':
      return {
        title: '入职签名',
        fn: returnPersonnelExtendSign
      }
    case 'personnelExtendRelevantAttachment':
      return {
        title: '相关附件',
        fn: returnPersonnelExtendRelevantAttachment
      }
    case 'personnelExtendWorkExperiences':
      break
    case 'personnelExtendEmergencyContact':
      break
    case 'personnelExtendMemberOfFamilies':
      break
    case 'personnelExtendCertificates':
      break
    case 'personnelExtendTrainingExperiences':
      break
    default:
      console.log(`sObjReturn-key:${key}没有匹配项`)
  }
}

export function sArrayReturn(key) {
  switch (key) {
    case 'personnelExtendEducationalExperiences':
      return {
        title: '教育经历',
        fn: returnPersonnelExtendEducationalExperiences
      }
    case 'personnelExtendWorkExperiences':
      return {
        title: '工作经历',
        fn: returnPersonnelExtendWorkExperiences
      }
    case 'personnelExtendMemberOfFamilies':
      return {
        title: '家庭成员',
        fn: returnPersonnelExtendMemberOfFamilies
      }
    case 'personnelExtendCertificates':
      return {
        title: '证书/证件',
        fn: returnPersonnelExtendCertificates
      }
    case 'personnelExtendTrainingExperiences':
      return {
        title: '培训经历',
        fn: returnPersonnelExtendTrainingExperiences
      }
    default:
      console.log(`sArrayReturn-key:${key}没有匹配项`)
  }
}

export function arrayFn(key, obj, returnObj) {
  // console.log(key, obj)
  // console.log(returnObj.fn)
  let fn = returnObj.fn
  let arr = []
  let itemArr = []
  let extArr = []
  obj.map(async item => {
    if (key === 'personnelExtendEducationalExperiences') {
      extArr.push({
        items: [{label: item.school, value: item.school}, {label: '', value: item.major}, {label: '', value: `${item.startTime.split('T')[0]}-${item.endTime.split('T')[0]}`}]
      })
      for (let k in item) {
        let _obj = await fn(k)
        if (_obj) {
          itemArr.push({
            key: k,
            label: _obj.name,
            value: item[k],
            disabled: true,
            type: _obj.type,
            defaultSelectList: _obj.dataList && item.dataList.length ? item.dataList : [],
            changeFn: () => {}
          })
        }
      }
    } else if (key === 'personnelExtendCertificates') {
      extArr.push({
        items: [{label: '', value: item.name}, {label: '', value: item.category}, {label: '', value: `${item.effectiveStartDate.split('T')[0]}-${item.effectiveEndDate.split('T')[0]}`}]
      })
      for (let k in item) {
        let _obj = await fn(k)
        if (_obj) {
          itemArr.push({
            key: k,
            label: _obj.name,
            value: item[k],
            disabled: true,
            type: _obj.type,
            defaultSelectList: _obj.dataList && item.dataList.length ? item.dataList : [],
            changeFn: () => {}
          })
        }
      }
    } else if (key === 'personnelExtendMemberOfFamilies') {
      extArr.push({
        items: [{label: '', value: item.name}, {label: '', value: item.workUnit}, {label: '', value: item.contactNumber}]
      })
      for (let k in item) {
        let _obj = await fn(k)
        if (_obj) {
          itemArr.push({
            key: k,
            label: _obj.name,
            value: item[k],
            disabled: true,
            type: _obj.type,
            defaultSelectList: _obj.dataList && item.dataList.length ? item.dataList : [],
            changeFn: () => {}
          })
        }
      }
    } else if (key === 'personnelExtendTrainingExperiences') {
      extArr.push({
        items: [{label: '', value: item.name}, {label: '', value: item.company}, {label: '', value: `${item.startTime.split('T')[0]}-${item.endTime.split('T')[0]}`}]
      })
      for (let k in item) {
        let _obj = await fn(k)
        if (_obj) {
          itemArr.push({
            key: k,
            label: _obj.name,
            value: item[k],
            disabled: true,
            type: _obj.type,
            defaultSelectList: _obj.dataList && item.dataList.length ? item.dataList : [],
            changeFn: () => {}
          })
        }
      }
    } else if (key === 'personnelExtendWorkExperiences') {
      extArr.push({
        items: [{label: '', value: item.post}, {label: '', value: item.workUnit}, {label: '', value: `${item.startTime.split('T')[0]}-${item.endTime.split('T')[0]}`}]
      })
      for (let k in item) {
        let _obj = await fn(k)
        if (_obj) {
          itemArr.push({
            key: k,
            label: _obj.name,
            value: item[k],
            disabled: true,
            type: _obj.type,
            defaultSelectList: _obj.dataList && item.dataList.length ? item.dataList : [],
            changeFn: () => {}
          })
        }
      }
    }
    arr.push({item: itemArr})
  })
  
  return {
    title: returnObj.title,
    list: arr,
    extendInfo: extArr,
  }
}


export async function objectFn(key, obj, returnObj) {
  // console.log(key, obj)
  // console.log(returnObj.fn)
  // switch(key) {
  //   case 'personnelExtendBasic':
  let fn = returnObj.fn
  let arr = []
  for (let k in obj) {
    if (k == 'personnelId') continue
    let _obj = await fn(k)
    // console.log('_obj:', _obj)
    if (k === 'hadChild' || k === 'isMarried' || k === 'isFirstAccumulationFund' || k === 'isFirstSocialSecurity' || k === 'isMain') {
      arr.push({
        key: k,
        label: _obj.name,
        value: obj[k] === true ? 1 : 0,
        disabled: true,
        type: _obj.type,
        defaultSelectList: _obj.dataList&&_obj.dataList.length?_obj.dataList:[],
        changeFn: () => {}
      })
    } else if (_obj.type === 'datePicker') {
      arr.push({
        key: k,
        label: _obj.name,
        value: obj[k] && obj[k] !== null ? obj[k].split('T')[0] : null,
        disabled: true,
        type: _obj.type,
        defaultSelectList: _obj.dataList&&_obj.dataList.length?_obj.dataList:[],
        changeFn: () => {}
      })
    }  else if (_obj.type === 'cascader') {
      arr.push({
        key: k,
        label: _obj.name,
        value: obj[k] ? obj[k].split('-') : [],
        disabled: true,
        type: _obj.type,
        defaultSelectList: _obj.dataList&&_obj.dataList.length?_obj.dataList:[],
        changeFn: () => {}
      })
    } else if (key === 'personnelExtendSign') {
      arr.push({
        key: k,
        label: _obj.name,
        value: obj[k] !== null ? 1 : 0,
        disabled: true,
        type: _obj.type,
        defaultSelectList: _obj.dataList&&_obj.dataList.length?_obj.dataList:[],
        changeFn: () => {}
      })
    } else if (key === 'personnelExtendRelevantAttachment') {
      let itemVal = []
      if (!obj[k]) continue
      if (obj[k].indexOf(',') !== -1) {
        let fileUrlArr = obj[k].split(',')
        fileUrlArr.map((val, i) => {
          let n = val.split("/");
          itemVal.push({
            uid: `-${i + 1}`,
            url: `${AppConsts.uploadBaseUrl}${val}`,
            name: n[n.length - 1],
            response: {data: {Path: val}},
            status: 'done',
          })
        })
      } else {
        let n = obj[k].split("/")
        itemVal.push({
          uid: `-1`,
          url: `${AppConsts.uploadBaseUrl}${obj[k]}`,
          name: n[n.length - 1],
          response: {data: {Path: obj[k]}},
          status: 'done',
        })
      }
      arr.push({
        key: k,
        label: _obj.name,
        value: itemVal,
        disabled: true,
        type: 'upload',
        defaultSelectList: _obj.dataList && _obj.dataList.length ? _obj.dataList : [],
        subdirName: environment.production ? 'abpProd' : 'abpDev',
        uploadUrl: AppConsts.uploadBaseUrl + '/API/Upload',
        limitedLength: _obj.maxLength,
        span: 12,
        changeFn: () => {}
      })
    } else { 
      arr.push({
        key: k,
        label: _obj.name,
        value: obj[k],
        disabled: true,
        type: _obj.type,
        defaultSelectList: _obj.dataList && _obj.dataList.length ? _obj.dataList : [],
        changeFn: () => {}
      })
    }
  }
  return {
    title: returnObj.title,
    list: arr,
  }
}


export async function initExtendArr(res) {
  let arr = []
  let multiArr = []
  for (let k in res) {
    if (k === 'personnelId') continue
    let _opts
    if (res[k] instanceof Array === false) {
      let obj = res[k]
      let newObj = {}
      for (let __k in obj) {
        if (__k !== 'personnelId') {
          newObj[__k] = obj[__k]
        }
      }
      _opts = await objectFn(k, newObj, sObjReturn(k))
      arr.push(_opts)
    } else if (res[k] instanceof Array === true) {
      _opts = await arrayFn(k, res[k], sArrayReturn(k))
      multiArr.push(_opts)
    }
  }
  return arr.concat(multiArr)
}

export function returnFilterData(obj) {
  let opts = {}
  for (let k in obj) {
    opts[k] = {}
    let _obj = obj[k]
    // console.log('_obj:', _obj)
    for (let _key in _obj) {
      // console.log('_obj[_key]:', typeof _obj[_key])
      opts[k][_key] =  typeof _obj[_key] === 'boolean' ? (_obj[_key] === true ? 1 : 0) : _obj[_key]
    }
  }
  console.log('returnFilterData:', opts)
}
