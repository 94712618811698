<template>
  <a-spin :spinning="spinning">
    <!-- <page-header title="人员管理"></page-header> -->
    <!-- <a-card :bordered="false"> -->
    <!-- <react-table
      :table-data="data"
      :total-items="totalItems"
      :show-total="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :hideRowSelection="true"
      :is-full="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="data"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <div style="">
        <!-- <a-form-model
          layout="inline"
          :model="searchFormInline"
          @submit="getData"
          @submit.native.prevent
        >
          <a-form-model-item label="关键字"> -->
        <a-row :gutter="4">
          <a-col :span="4">
            <a-input
              v-model.trim="searchFormInline.filterText"
              placeholder="姓名/身份证/手机号"
            >
              <a-icon
                slot="prefix"
                type="key"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-col>
          <!-- </a-form-model-item> -->
          <!-- <a-form-model-item label="实名状态"> -->
          <a-col :span="3">
            <a-select
              style="width: 100%"
              @change="selectChange"
              placeholder="实名状态"
              v-model="searchFormInline.isRealNamed"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in realNamedStatusList"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3">
            <a-select
              style="width: 100%"
              @change="selectChange"
              placeholder="实名类型"
              v-model="searchFormInline.realNamedType"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in realNamedTypeList"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <!-- </a-form-model-item> -->
          <!-- <a-form-model-item> -->
          <a-col :span="4">
            <a-button-group>
              <a-button
                type="primary"
                @click="search"
                v-if="isGranted('Pages.UnnaturalPersonnelPermissions.Query')"
              >
                <a-icon type="search" /> <span> {{ l("Search") }}</span>
              </a-button>
              <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
            </a-button-group>
          </a-col>
          <!-- <a-form-model-item>
              <a-button
                v-if="isGranted('Pages.LGModul.Personal.Create')"
                type="primary"
                @click="createOrEdit(null, 'create')"
              >
                <a-icon type="plus" />
                {{ l("Create") }}
              </a-button>
            </a-form-model-item> -->

          <!-- <a-form-model-item> -->
          <a-col :span="10" style="text-align: right">
            <a-button-group>
              <a-button
                v-if="
                  isGranted(
                    'Pages.UnnaturalPersonnelPermissions.WyImportExtendInfo'
                  )
                "
                @click="openImportform('wywk')"
              >
                <!-- <a-icon type="file-excel" /> -->
                <span>{{ l("网鱼网咖导入") }}</span>
              </a-button>
              <a-button
                v-if="
                  isGranted(
                    'Pages.UnnaturalPersonnelPermissions.ImportExtendInfo'
                  )
                "
                @click="openImportform('extend')"
              >
                <!-- <a-icon type="file-excel" /> -->
                <span>{{ l("扩展信息导入") }}</span>
              </a-button>
              <a-button
                v-if="
                  isGranted('Pages.UnnaturalPersonnelPermissions.ExtendExport')
                "
                @click="extendExportToExcel()"
              >
                <!-- <a-icon type="file-excel" /> -->
                <span>{{ l("导出登记信息") }}</span>
              </a-button>
              <a-button
                v-if="isGranted('Pages.UnnaturalPersonnelPermissions.Export')"
                @click="exportToExcel()"
              >
                <!-- <a-icon type="file-excel" /> -->
                <span>{{ l("Export") }}</span>
              </a-button>
            </a-button-group>
          </a-col>
        </a-row>
      </div>
    </ele-table>
    <!-- </a-card> -->
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy, initVerifiedWay } from "@/shared/common-service";
import { PersonnelServiceProxy } from "./services/personnel-proxies";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";
import CreateOrEditPersonnel from "./create-or-edit-personnel/create-or-edit-personnel";
import bankInfoList from "./bankInfo-list/bankInfo-list";
import presonnelSkillList from "./personnelSkills-list/personnelSkills-list";
import { personnelType } from "./services/enum/personnel.js";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import { initExtendArr, returnFilterData } from "./utils";
import ExtendInfo from "./extend-info/extend-info";
import ImportForm from "./importform/importform.vue";
import ClearRealName from "../../linggong/personnel/clear-realname/clear-realname.vue";

export default {
  mixins: [AppComponentBase],
  name: "personnel-index",
  components: { EleTable, ExtendInfo },
  data() {
    return {
      spinning: false,
      selectedRowKeys: [],
      name: "",
      // 搜索
      filterText: "",
      realName: "",
      idCard: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [
        // {
        //   title: "人员类型",
        //   dataIndex: "typeStr",
        //   sorter: false,
        //   align: "center",
        //   scopedSlots: { customRender: "type" },
        // },
        {
          title: "姓名",
          dataIndex: "realName",
          sorter: false,
          // align: "center",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: "是否实名",
          dataIndex: "isRealNamed",
          sorter: true,
          align: "center",
          type: "tag",
          scopedSlots: { customRender: "isRealNamed" },
          customRender: (text) => {
            if (text == "已实名") {
              return <a-tag color="#87d068">已实名</a-tag>;
            }
            return <a-tag color="#f50">未实名</a-tag>;
          },
        },
        {
          title: "实名类型",
          dataIndex: "verifiedWay",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "verifiedWay" },
          customRender: (text) => {
            if (text == "ThreeElements") {
              return "纯三要素";
            }
            if (text == "FourElements") {
              return "纯四要素";
            }
            if (text == "FaceRecognition") {
              return "人脸识别";
            }
            return "未实名";
          },
        },
        {
          title: "手机号码",
          dataIndex: "phoneNumber",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: "身份证号码",
          dataIndex: "idCard",
          // sorter: true,
          // align: "center",
          width: 200,
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: "状态",
          dataIndex: "status",
          sorter: true,
          align: "center",
          type: "tag",
          scopedSlots: { customRender: "status" },
          customRender: (text) => {
            if (text == "1") {
              return <a-tag color="#87d068">启用</a-tag>;
              // return <a-switch default-checked />;
            }
            return <a-tag color="#f50">禁用</a-tag>;
          },
        },
        {
          title: "银行卡号",
          dataIndex: "bankCardNo",
          // sorter: true,
          // align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "bankCardNo" },
        },
        {
          title: "开户行",
          dataIndex: "bankName",
          // sorter: true,
          // align: "center",
          scopedSlots: { customRender: "bankName" },
        },
        {
          title: "所属分行",
          dataIndex: "bankArea",
          // sorter: true,
          // align: "center",
          scopedSlots: { customRender: "bankArea" },
        },
                {
          title: "地址",
          dataIndex: "address",
          // sorter: true,
          // align: "center",
          scopedSlots: { customRender: "bankArea" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          // sorter: trueaction,
          //align: "center",
          width: 180,
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      data: [],
      _serviceProxy: "",
      commonService: null,
      realNamedStatusList: [
        {
          value: 1,
          label: "已实名",
        },
        {
          value: 0,
          label: "未实名",
        },
      ],
      realNamedTypeList: [
        {
          value: 4,
          label: "纯三要素",
        },
        {
          value: 5,
          label: "纯四要素",
        },
        {
          value: 9,
          label: "人脸识别",
        },
      ],
      searchFormInline: {
        isRealNamed: undefined,
        realNamedType: undefined,
        filterText: undefined,
      },
    };
  },
  created() {
    // console.log(personnelType);
    this._serviceProxy = new PersonnelServiceProxy(this.$apiUrl, this.$api);
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.getData();
    this.initActionsType();
  },
  methods: {
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "personal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonnelPermissions.Edit"
              ),
              name: this.l("Edit"),
              icon: "profile",
              width: "50%",
              fn: (data) => {
                _this.editPersonnel(data);
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonnelPermissions.BankCardInfo"
              ),
              name: this.l("银行卡信息"),
              icon: "edit",
              width: "50%",
              fn: (data) => {
                _this.bankInfoList(data);
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonnelPermissions.IdCardInfo"
              ),
              name: this.l("身份证信息"),
              icon: "idcard",
              width: "50%",
              fn: (data) => {
                _this.uploadModal(data, "edit");
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonnelPermissions.PersonnelSkill"
              ),
              name: this.l("人员技能"),
              icon: "profile",
              width: "50%",
              fn: (data) => {
                _this.personnelSkillList(data, "edit");
              },
            },
            // { granted: true, name: this.l("查看身份证"), icon: "idcard", fn: (data) => {
            //   _this.uploadModal(data, 'preview');
            // } },
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonnelPermissions.DynamicExtendInfo"
              ),
              name: this.l("动态扩展信息"),
              icon: "build",
              width: "50%",
              fn: (data) => {
                _this.createReactModify(data, "edit");
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonnelPermissions.JoinCompany"
              ),
              name: this.l(" 入职登记表"),
              icon: "container",
              width: "50%",
              fn: (data) => {
                _this._getByPersonnelId(data, "others");
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonnelPermissions.UnbindWeChatMpOpenId"
              ),
              name: this.l("微信解绑"),
              icon: "disconnect",
              fn: (data) => {
                abp.message.confirm(
                  "是否确定解除微信绑定？",
                  "系统提示",
                  (result) => {
                    if (result) {
                      _this._unbindWeChatMpOpenId(data.id);
                    }
                  }
                );
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonnelPermissions.UnbindIdcardInfo"
              ),
              name: this.l(" 个人信息解绑"),
              icon: "disconnect",
              fn: (data) => {
                abp.message.confirm(
                  "是否确定解除个人信息绑定？",
                  "系统提示",
                  (result) => {
                    if (result) {
                      _this._unbindIdcardInfo(data.id);
                    }
                  }
                );
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonnelPermissions.UnbindMobile"
              ),
              name: this.l(" 手机号解绑"),
              icon: "disconnect",
              fn: (data) => {
                abp.message.confirm(
                  "是否确定解除手机号绑定？",
                  "系统提示",
                  (result) => {
                    if (result) {
                      _this._unbindMobile(data.id);
                    }
                  }
                );
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonnelPermissions.Clear.SureName"
              ),
              name: this.l("重置实名"),
              icon: "disconnect",
              fn: (data) => {
                _this.showClearRealName(data);
              },
            },
          ],
          // delete: { granted: true, name: this.l("Delete"), fn: (data) => {
          //   _this.deletePersonnel(data)
          // } },
          delete: {
            granted: this.isGranted(
              "Pages.UnnaturalPersonnelPermissions.StopOrStart"
            ),
            width: "50%",
            name: this.l("禁/启用"),
            tipsDesc: "禁/启用人员?",
            fn: (data) => {
              _this._changeStatus(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.realName = "";
      this.idCard = "";
      this.searchFormInline.filterText = "";
      this.searchFormInline.isRealNamed = undefined;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    uploadModal(item, type) {
      const list = [
        {
          label: "身份证正面照片",
          value: [],
          type: "upload",
          disabled: false,
          required: false,
          rules: [],
          subdirName: environment.production ? "abpProd" : "abpDev",
          uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
          limitedLength: 1,
          showRemoveIcon: item.isRealNamed === "已实名" ? "0" : "1",
          span: 24,
          changeFn: (info) => {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
              }
              return file;
            });
            list[0].value = fileList;
          },
        },
        {
          label: "身份证反面照片",
          value: [],
          type: "upload",
          disabled: false,
          required: false,
          rules: [],
          subdirName: environment.production ? "abpProd" : "abpDev",
          uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
          limitedLength: 1,
          showRemoveIcon: item.isRealNamed === "已实名" ? "0" : "1",
          span: 24,
          changeFn: (info) => {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
              }
              return file;
            });
            list[1].value = fileList;
          },
        },
        {
          label: "半身照",
          value: [],
          type: "upload",
          disabled: false,
          required: false,
          rules: [],
          subdirName: environment.production ? "abpProd" : "abpDev",
          uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
          limitedLength: 1,
          showRemoveIcon: item.isRealNamed === "已实名" ? "0" : "1",
          span: 24,
          changeFn: (info) => {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
              }
              return file;
            });
            list[2].value = fileList;
          },
        },
      ];
      let reqParams = [];
      if (item && item.id) reqParams.push({ key: "id", value: item.id });
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [
              ...reqParams,
              // {key: `${this.reqKey === 'Organization' ? 'organizationUnitId' : 'customerId'}`, value: this.id},
            ],
            reqUrl: {
              getById: "/api/services/app/Personnel/GetPersonnelById",
              save: "/api/services/app/Personnel/UpdateIdCardPhotoInfo",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              // form.setFieldsValue({...res})
              if (
                res.idCardPhotoFront &&
                res.idCardPhotoFront.indexOf(",") !== -1
              ) {
                let arr = res.split(",");
                arr.map((item, i) => {
                  let n = item.split("/");
                  list[0].value.push({
                    uid: `-${i + 1}`,
                    url: `${AppConsts.uploadBaseUrl}${item}`,
                    name: n[n.length - 1],
                    response: { data: { Path: item } },
                    status: "done",
                  });
                });
              } else if (res.idCardPhotoFront) {
                let n = res.idCardPhotoFront.split("/");
                list[0].value.push({
                  uid: `-1`,
                  url: `${AppConsts.uploadBaseUrl}${res.idCardPhotoFront}`,
                  name: n[n.length - 1],
                  response: { data: { Path: res.idCardPhotoFront } },
                  status: "done",
                });
              }
              if (
                res.idCardPhotoVerso &&
                res.idCardPhotoVerso.indexOf(",") !== -1
              ) {
                let arr = res.split(",");
                arr.map((item, i) => {
                  let n = item.split("/");
                  list[1].value.push({
                    uid: `-${i + 1}`,
                    url: `${AppConsts.uploadBaseUrl}${item}`,
                    name: n[n.length - 1],
                    response: { data: { Path: item } },
                    status: "done",
                  });
                });
              } else if (res.idCardPhotoVerso) {
                let n = res.idCardPhotoVerso.split("/");
                list[1].value.push({
                  uid: `-1`,
                  url: `${AppConsts.uploadBaseUrl}${res.idCardPhotoVerso}`,
                  name: n[n.length - 1],
                  response: { data: { Path: res.idCardPhotoVerso } },
                  status: "done",
                });
              }
              if (
                res.personnelPhoto &&
                res.personnelPhoto.indexOf(",") !== -1
              ) {
                let arr = res.split(",");
                arr.map((item, i) => {
                  let n = item.split("/");
                  list[list.length - 1].value.push({
                    uid: `-${i + 1}`,
                    url: `${AppConsts.uploadBaseUrl}${item}`,
                    name: n[n.length - 1],
                    response: { data: { Path: item } },
                    status: "done",
                  });
                });
              } else if (res.personnelPhoto) {
                let n = res.personnelPhoto.split("/");
                list[list.length - 1].value.push({
                  uid: `-1`,
                  url: `${AppConsts.uploadBaseUrl}${res.personnelPhoto}`,
                  name: n[n.length - 1],
                  response: { data: { Path: res.personnelPhoto } },
                  status: "done",
                });
              }
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              // if (!list[list.length - 1].value.length) {
              //   this.$message.info("请上传附件资源");
              //   return;
              // }
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);

              const idCardPhotoFront = list[0].value.length
                ? list[0].value[0].response.data.Path
                : null;
              const idCardPhotoVerso = list[1].value.length
                ? list[1].value[0].response.data.Path
                : null;
              const personnelPhoto = list[2].value.length
                ? list[2].value[0].response.data.Path
                : null;

              try {
                // console.log({
                //   url: queryParams.reqUrl.save,
                //   params: {...values, pictureUrls: pictureUrls, status: Number(values.status)},
                // })
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: {
                    ...values,
                    idCardPhotoFront: idCardPhotoFront,
                    idCardPhotoVerso: idCardPhotoVerso,
                    personnelPhoto: personnelPhoto,
                  },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    // 静态扩展信息
    async _getByPersonnelId(item, type) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/PersonnelExtend/GetByPersonnelId",
          params: { personnelId: item.id },
        });
        this.spinning = false;
        // console.log(await initExtendArr(res))
        // let arr = await initExtendArr(res)
        this.previewExtendInfo(item, type, res);
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _unbindWeChatMpOpenId(personnelId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Personnel/UnbindWeChatMpOpenId",
          params: { personnelId: personnelId },
        });
        this.spinning = false;
        this.notify.success("解绑成功！");
        this.getData();
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _unbindIdcardInfo(personnelId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Personnel/UnbindIdcardInfo",
          params: { personnelId: personnelId },
        });
        this.spinning = false;
        this.notify.success("解绑成功！");
        this.getData();
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    showClearRealName(item) {
      ModalHelper.create(
        ClearRealName,
        {
          id: item.id,
        },
        {
          isChange: true,
          width: "850px",
        }
      ).subscribe((res) => {
        if (res) {
          this.getData();
        }
      });
    },
    async _unbindMobile(personnelId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Personnel/UnbindMobile",
          params: { personnelId: personnelId },
        });
        this.spinning = false;
        this.notify.success("解绑成功！");
        this.getData();
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async previewExtendInfo(item, type, resData) {
      let list = await initExtendArr(resData);
      console.log("list:", list);
      const orginListLength = list.length;
      let reqParams = [];
      if (item && item.id) {
        reqParams.push({ key: "id", value: item.id });
      }
      let otherPrams = {};
      ModalHelper.create(
        ExtendInfo,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById: "",
              save: "",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              // console.log(resData)
              for (let k in resData) {
                let _obj = resData[k];
                // form.setFieldsValue({
                //   ..._obj
                // })
                if (
                  resData[k] instanceof Array === false &&
                  k !== "personnelExtendRelevantAttachment"
                ) {
                  for (let key in _obj) {
                    if (
                      key === "hadChild" ||
                      key === "isMarried" ||
                      key === "isFirstAccumulationFund" ||
                      key === "isFirstSocialSecurity"
                    ) {
                    } else {
                      this.$nextTick(() => {
                        // console.log(key, _obj[key])
                        // form.setFieldsValue({
                        //   key: _obj[key]
                        // })
                        // console.log(form.getFieldsValue())
                      });
                    }
                  }
                } else {
                }
              }
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              // ...
              try {
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "1000px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    // 动态扩展信息
    async createReactModify(item, type) {
      let list = [];
      const orginListLength = list.length;
      let reqParams = [];
      if (item && item.id) {
        // console.log(item)
        reqParams.push({ key: "id", value: item.id });
      }
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById: "/api/services/app/Personnel/GetExtendInfoById",
              save: "/api/services/app/Personnel/SaveExtendInfo",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              res.formItems.map((_item, index) => {
                switch (_item.itemType) {
                  case "Text":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonInput",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => {},
                    });
                    break;
                  case "Textarea":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "textarea",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => {},
                    });
                    break;
                  case "Number":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "numberInput",
                      disabled: false,
                      required: _item.isRequire,
                      max: _item.maxLength,
                      min: 0,
                      key: _item.key,
                      rules: [],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => {},
                    });
                    break;
                  case "Select":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonSelect",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: "",
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Checkbox":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "checkbox",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: null,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Radio":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "radio",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: null,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Date":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "datePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "object",
                          required: _item.isRequire,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => {},
                    });
                    break;
                  case "Upload":
                    list.push({
                      label: _item.name,
                      value: [],
                      type: "upload",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [],
                      subdirName: environment.production ? "abpProd" : "abpDev",
                      uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
                      limitedLength: _item.maxLength,
                      span: 24,
                      changeFn: (info) => {
                        let fileList = [...info.fileList];
                        fileList = fileList.map((file) => {
                          if (file.response) {
                            file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
                          }
                          return file;
                        });
                        list[_item.sort + orginListLength].value = fileList;
                      },
                    });
                    break;
                  case "DateRange":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "rangeDatePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "array",
                          required: _item.isRequire,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => {},
                    });
                    break;
                  default:
                    throw new Error("暂无匹配项目");
                }
              });

              if (!item) {
              } else {
                form.setFieldsValue({
                  ProjectName: item.projectName,
                  ProjectType: item.projectType,
                  OrganizationUnitId: item.organizationUnitId,
                  ProjectTime: [item.beginDate, item.endDate],
                  CustomerName: item.customerName,
                  ProjectCode: item.projectCode,
                });
              }

              if (res.results && Object.keys(res.results).length !== 0) {
                const oResult = res.results;
                let opts = {};
                list.map((it) => {
                  if (it.key) {
                    let k = it.key;
                    if (it.type === "upload") {
                      if (oResult[k].value.indexOf(",") !== -1) {
                        let arr = oResult[k].value.split(",");
                        arr.map((val, i) => {
                          let n = val.split("/");
                          it.value.push({
                            uid: `-${i + 1}`,
                            url: `${AppConsts.uploadBaseUrl}${val}`,
                            name: n[n.length - 1],
                            response: { data: { Path: val } },
                            status: "done",
                          });
                        });
                      } else {
                        if (oResult[k].value && oResult[k].value.length) {
                          let n = oResult[k].value.split("/");
                          it.value.push({
                            uid: `-1`,
                            url: `${AppConsts.uploadBaseUrl}${res.pictureUrls}`,
                            name: n[n.length - 1],
                            response: { data: { Path: res.pictureUrls } },
                            status: "done",
                          });
                        }
                      }
                    } else if (it.type === "rangeDatePicker") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf("至") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split("至");
                    } else if (it.type === "datePicker") {
                      opts[`${k}`] = oResult[k].value
                        ? moment(oResult[k].value, "YYYY-MM-DD")
                        : null;
                    } else if (it.type === "checkbox") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf(",") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split(",");
                    } else {
                      opts[`${k}`] = oResult[k].value;
                    }
                  }
                });
                // console.log(opts)
                this.$nextTick(() => {
                  form.setFieldsValue({ ...opts });
                });
              }

              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let fileUrl = [];
              for (let i = 0; i < list.length; i++) {
                if (list[i].type === "upload" && list[i].required) {
                  if (!list[i].value.length) {
                    this.$message.info(`请上传${list[i].label}的附件资源`);
                    break;
                  }
                }
              }

              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);

              let dynamicList = list.filter((item) => item.key);
              let _results = [];
              // console.log(dynamicList)

              dynamicList.map((_item) => {
                if (
                  _item.type === "commonInput" ||
                  _item.type === "numberInput" ||
                  _item.type === "textarea" ||
                  _item.type === "radio" ||
                  _item.type === "commonSelect"
                ) {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value: values[`${_item.value}`]
                        ? values[`${_item.value}`]
                        : null,
                    },
                  });
                } else if (_item.type === "datePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      // value: values[`${_item.value}`].format('YYYY-MM-DD')
                      value: values[`${_item.value}`],
                    },
                  });
                } else if (_item.type === "rangeDatePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`][0] +
                        "至" +
                        values[`${_item.value}`][1],
                    },
                  });
                } else if (_item.type === "checkbox") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`].length === 1
                          ? values[`${_item.value}`][0]
                          : values[`${_item.value}`].join(","),
                    },
                  });
                } else if (_item.type === "upload") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        _item.value.length === 1
                          ? `${_item.value[0].response.data.Path}`
                          : _item.value
                              .map((v) => {
                                return v.response.data.Path;
                              })
                              .join(","),
                    },
                  });
                }
              });

              let submitData = {
                personnelId: item && item.id ? item.id : null,
                results: _results,
              };
              // console.log('submitData:', submitData)

              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: { ...submitData },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    async _changeStatus(id) {
      this.spinning = true;
      try {
        const res = await this.commonService.post({
          url: "/api/services/app/Personnel/ChangeStatus",
          params: { id },
        });
        this.getData();
        this.$notification["success"]({
          message: this.l("更新人员成功"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    search() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      // console.log(this.request)
      this._serviceProxy
        .getPaged(
          "",
          "",
          "",
          // 1,
          this.searchFormInline.realNamedType === undefined
            ? ""
            : this.searchFormInline.realNamedType,
          this.searchFormInline.isRealNamed === undefined
            ? ""
            : this.searchFormInline.isRealNamed === 1
            ? true
            : false,
          this.searchFormInline.filterText,
          this.request.sorting,
          this.request.maxResultCount,
          this.request.skipCount
        )
        .finally(() => {
          this.spinning = false;
        })
        .then((result) => {
          this.data = result.items.map((o) => {
            (o.typeStr = personnelType[o.type]),
              (o.isRealNamed = o.isRealNamed ? "已实名" : "未实名");
            return {
              ...o,
              isRealNamedColor: o.isRealNamed === "已实名" ? "#87d068" : "#f50",
              status: o.status ? "启用" : "禁用",
              statusColor: o.status ? "#87d068" : "#f50",
              verifiedWay: initVerifiedWay(o.verifiedWay),
            };
          });
          // console.log(this.data);
          this.totalItems = result.totalCount;
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
          this.totalPages = Math.ceil(
            result.totalCount / this.request.maxResultCount
          );
        });
    },
    /**
     * 选中table
     */
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleChange(pagination, filters, sorter) {
      console.log("Various parameters", pagination, filters, sorter);
      // this.filteredInfo = filters;
      // this.sortedInfo = sorter;
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.searchFormInline.filterText = "";
      this.searchFormInline.isRealNamed = undefined;
      this.searchFormInline.realNamedType = undefined;
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 新建修改
     */
    createOrEdit(item, type) {
      ModalHelper.create(
        CreateOrEditPersonnel,
        {
          query: {
            id: item ? item.id : "",
            // isRealNamed: item?item.isRealNamed:false,
            isRealNamed: item && item.isRealNamed == "已实名" ? true : false,
          },
        },
        {
          width: "800px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          // this.refreshGoFirstPage();
          this.getData();
        }
      });
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * 分页
     */
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    editPersonnel(item) {
      console.log(item);
      this.createOrEdit(item, "create");
    },
    bankInfoList(data) {
      ModalHelper.create(
        bankInfoList,
        {
          id: data.id,
          isRealNamed: data.isRealNamed === "已实名" ? "1" : "0",
        },
        {
          width: "900px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    personnelSkillList(data) {
      ModalHelper.create(
        presonnelSkillList,
        {
          id: data.id,
        },
        {
          width: "900px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    deletePersonnel(data) {
      let id = data.id;
      this._serviceProxy
        .deletePersonnel(id)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    clearData() {
      this.selectedRowKeys = [];
    },
    refreshData() {
      this.getData();
    },
    /**
     * 导出为excel
     */
    exportToExcel() {
      this.spinning = true;
      this._serviceProxy
        .getToExcelFile({
          personnelTag: 2,
          isRealNamed:
            this.searchFormInline.isRealNamed === undefined
              ? ""
              : this.searchFormInline.isRealNamed === 1
              ? true
              : false,
          filterText: this.searchFormInline.filterText,
          sorting: this.request.sorting,
          maxResultCount: this.totalItems,
          skipCount: 0,
        })
        .then((result) => {
          this.spinning = false;
          fileDownloadService.downloadTempFile(result);
        });
    },
    /**
     * 导出为excel
     */
    extendExportToExcel() {
      this.spinning = true;
      this._serviceProxy
        .getExtendToExcelFile({
          personnelTag: 2,
          isRealNamed:
            this.searchFormInline.isRealNamed === undefined
              ? ""
              : this.searchFormInline.isRealNamed === 1
              ? true
              : false,
          filterText: this.searchFormInline.filterText,
          sorting: this.request.sorting,
          maxResultCount: this.totalItems,
          skipCount: 0,
        })
        .then((result) => {
          this.spinning = false;
          fileDownloadService.downloadTempFile(result);
        })
        .catch(() => {
          this.spinning = false;
        });
    },
    openImportform(type) {
      ModalHelper.create(
        ImportForm,
        { formId: "22222222-2222-2222-2222-222222222222", type: type },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        this.refreshGoFirstPage();
      });
    },
    selectChange(value) {
      // this.isRealNamed = value;
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin-bottom: 20px;
}
.btn--footer {
  // border: 1px solid #e8e8e8;
  // margin: 20px;
  // padding: 20px;
  .table-delete {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.btn--footer {
  margin-bottom: 20px;
}
</style>
