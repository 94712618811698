import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { FileDto, SwaggerException } from "@/shared/service-proxies/model";
export class PersonnelServiceProxy {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver:
        | ((key: string, value: any) => any)
        | undefined = undefined;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl ? baseUrl : "";
    }
    //分页获取人员信息
    async getPaged(
        realName: string | undefined,
        phoneNumber: string | undefined,
        idCard: string | undefined,
        realNameType: number | undefined,
        isRealNamed: number | undefined,
        filterText: string | undefined,
        sorting: string | undefined,
        maxResultCount: number | undefined,
        skipCount: number | undefined
    ): Promise<any> {
        // let url_ = this.baseUrl + `/api/services/app/Personnel/GetPaged?realName=${realName}&phoneNumber=${phoneNumber}&idCard=${idCard}&status=${status}&isRealNamed=${isRealNamed}&`;
        let url_ = this.baseUrl + `/api/services/app/Personnel/GetPaged?realName=${realName}&personnelTag=2&phoneNumber=${phoneNumber}&idCard=${idCard}&isRealNamed=${isRealNamed}&realNameType=${realNameType}&`;
        if (filterText === null) {
            throw new Error("The parameter 'filterText' cannot be null.");
        } else if (filterText !== undefined) {
            url_ += "filterText=" + encodeURIComponent("" + filterText) + "&";
        }
        if (sorting === null) {
            throw new Error("The parameter 'sorting' cannot be null.");
        } else if (sorting !== undefined) {
            url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
        }
        if (maxResultCount === null) {
            throw new Error("The parameter 'maxResultCount' cannot be null.");
        } else if (maxResultCount !== undefined) {
            url_ += "maxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
        }
        if (skipCount === null) {
            throw new Error("The parameter 'skipCount' cannot be null.");
        } else if (skipCount !== undefined) {
            url_ += "skipCount=" + encodeURIComponent("" + skipCount) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetPaged(_response);
    }

    async getRosterPaged(
        realName: string | undefined,
        phoneNumber: string | undefined,
        idCard: string | undefined,
        status: number | undefined,
        isRealNamed: number | undefined,
        filterText: string | undefined,
        sorting: string | undefined,
        maxResultCount: number | undefined,
        skipCount: number | undefined
    ): Promise<any> {
        let url_ = this.baseUrl + `/api/services/app/Personnel/GetPersonnelRostersByPaged?realName=${realName}&phoneNumber=${phoneNumber}&idCard=${idCard}&status=${status}&isRealNamed=${isRealNamed}&`;
        if (filterText === null) {
            throw new Error("The parameter 'filterText' cannot be null.");
        } else if (filterText !== undefined) {
            url_ += "filterText=" + encodeURIComponent("" + filterText) + "&";
        }
        if (sorting === null) {
            throw new Error("The parameter 'sorting' cannot be null.");
        } else if (sorting !== undefined) {
            url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
        }
        if (maxResultCount === null) {
            throw new Error("The parameter 'maxResultCount' cannot be null.");
        } else if (maxResultCount !== undefined) {
            url_ += "maxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
        }
        if (skipCount === null) {
            throw new Error("The parameter 'skipCount' cannot be null.");
        } else if (skipCount !== undefined) {
            url_ += "skipCount=" + encodeURIComponent("" + skipCount) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetPaged(_response);
    }

    protected processGetPaged(
        response: AxiosResponse
    ): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            // result200 = PagedResultDtoOfCompanyListDto.fromJS(resultData200);
            return resultData200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>null);
    }
    async getEditByIdForBankInfo(id: string | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/Personnel/GetEditByIdForBankInfo?id=" + id;
        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetEditByIdForBankInfo(_response);
    }
    protected processGetEditByIdForBankInfo(
        response: AxiosResponse
    ): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            console.log(response)
            // result200 = CollectionFormItemDto.formJson(resultData200);
            return resultData200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>null);
    }
    async getEditById(id: string | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/Personnel/GetEditById?id=" + id;
        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetForEdit(_response);
    }
    protected processGetForEdit(
        response: AxiosResponse
    ): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            return resultData200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>null);
    }
    async getPagedForBanInfo(personnelId: string | undefined, bankCardNo: string | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/Personnel/GetPagedForBanInfo?personnelId=" + personnelId + '&bankCardNo=' + bankCardNo;
        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetPagedForBanInfo(_response);
    }
    protected processGetPagedForBanInfo(
        response: AxiosResponse
    ): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            return resultData200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>null);
    }
    async getPagedForPersonnelSkills(personnelId: string): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/Personnel/GetPagedForPersonnelSkills?personnelId=" + personnelId;
        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetPagedForPersonnelSkills(_response);
    }
    protected processGetPagedForPersonnelSkills(
        response: AxiosResponse
    ): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            return resultData200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>null);
    }
    async createOrUpdate(body: any | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/Personnel/SavePersonnel";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);
        console.log(content_);
        let options_ = <AxiosRequestConfig>{
            data: content_,
            method: "POST",
            url: url_,
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        };
        var res = await this.instance.request(options_);
        var fnc = await this.processCreateOrUpdate(res);

        return fnc;
    }
    protected processCreateOrUpdate(response: AxiosResponse): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<any>(<any>response);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>response);
    }
    async saveBankInfo(body: any | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/Personnel/SaveBankInfo";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);
        console.log(content_);
        let options_ = <AxiosRequestConfig>{
            data: content_,
            method: "POST",
            url: url_,
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        };
        var res = await this.instance.request(options_);
        console.log(res);
        var fnc = await this.processSaveBankInfo(res);

        return fnc;
    }
    protected processSaveBankInfo(response: AxiosResponse): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        console.log(response);
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<any>(<any>response);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            console.log(_responseText);
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>response);
    }
    async setBankDefault(id: string | undefined): Promise<any> {
        let url_ = this.baseUrl + `/api/services/app/Personnel/SetBankDefault?id=${id}`;
        url_ = url_.replace(/[?&]$/, "");

        // const content_ = JSON.stringify(body);
        // console.log(content_);
        let options_ = <AxiosRequestConfig>{
            data: "",
            method: "POST",
            url: url_,
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        };
        var res = await this.instance.request(options_);
        var fnc = await this.processSetBankDefault(res);

        return fnc;
    }
    protected processSetBankDefault(response: AxiosResponse): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<any>(<any>response);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>response);
    }
    deleteBankInfo(id: string | undefined): Promise<void> {
        console.log(id);
        let url_ = this.baseUrl + "/api/services/app/Personnel/DeleteBankInfo?";
        if (id === null) {
            throw new Error("The parameter 'id' cannot be null.");
        } else if (id !== undefined) {
            url_ += "id=" + encodeURIComponent("" + id) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");
        console.log(url_);
        let options_ = <AxiosRequestConfig>{
            method: "DELETE",
            url: url_,
            headers: {},
        };

        return this.instance.request(options_).then((_response: AxiosResponse) => {
            return this.processDelete(_response);
        });
    }
    deletePersonnel(id: string | undefined): Promise<void> {
        console.log(id);
        let url_ = this.baseUrl + "/api/services/app/Personnel/DeletePersonnel?";
        if (id === null) {
            throw new Error("The parameter 'id' cannot be null.");
        } else if (id !== undefined) {
            url_ += "id=" + encodeURIComponent("" + id) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");
        console.log(url_);
        let options_ = <AxiosRequestConfig>{
            method: "DELETE",
            url: url_,
            headers: {},
        };

        return this.instance.request(options_).then((_response: AxiosResponse) => {
            return this.processDelete(_response);
        });
    }
    protected processDelete(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<void>(<any>null);
    }
    async getToExcelFile(param: any): Promise<FileDto> {
        let url_ = this.baseUrl + "/api/services/app/Personnel/GetToExcelFileUnnatrul";
        url_ = url_.replace(/[?&]$/, "");
        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            params: param,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetToExcelFile(_response);
    }
    async getExtendToExcelFile(param: any): Promise<FileDto> {
        let url_ = this.baseUrl + "/api/services/app/PersonnelExtendExport/ExportPersonalExtendInfo";
        url_ = url_.replace(/[?&]$/, "");
        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            params: param,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processGetToExcelFile(_response);
    }

    protected processGetToExcelFile(response: AxiosResponse): Promise<FileDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            result200 = FileDto.fromJS(resultData200);
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<FileDto>(<any>null);
    }

    async getBankInfoByJd(bankCardNo: string | undefined): Promise<any> {
        let url_ = this.baseUrl + "/api/services/app/Personnel/getBankInfoByJd?bankCardNo=" + bankCardNo;
        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                Accept: "text/plain",
            },
        };

        const _response = await this.instance.request(options_);
        return this.processCommon(_response);
    }
    protected processCommon(
        response: AxiosResponse
    ): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            console.log(response)
            // result200 = CollectionFormItemDto.formJson(resultData200);
            return resultData200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<any>(<any>null);
    }
}

function throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
): any {
    console.log(result);
    if (result !== null && result !== undefined) {
        throw result;
    } else {
        throw new SwaggerException(message, status, response, headers, null);
    }
}
